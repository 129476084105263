<template>
    <ion-page>
        <div id="container">
            <ion-text color="danger" v-show="errors">
                <h2>{{ errors }}</h2>
            </ion-text>
            <ion-row v-if="this.settings.systemType === 'Days' && parseInt(this.settings.defaultTime) > 1">
                <ion-col size="10" offset="1">
                    <h2>How many {{ this.dayNightsHours }} will you be staying for?</h2>
                    <ion-item id="sliderOverflow">
                        <ion-segment color="success" scrollable="true" v-on:ionChange="this.lengthOfStay = $event.target.value">
                            <ion-segment-button v-for="n in parseInt(this.settings.defaultTime)" :key="n" :value="n">{{ n }}</ion-segment-button>
                        </ion-segment>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row v-if="this.settings.useZones === '1' && parseInt(this.settings.siteNumber) > 0">
                <ion-col size="10" offset="1">
                    <h2>Which zone are you parked in?</h2>
                    <select id="zoneSelect" v-on:change="setZone($event.target.value)">
                        <option v-for="n in parseInt(this.settings.siteNumber)" :key="n" :value="this.settings.zoneCode[n-1]">{{ this.settings.zoneName[n-1] }}</option>
                    </select>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="10" offset="1">
                    <input
                        class="reg2"
                        type="text"
                        name="vrm"
                        id="vrm"
                        maxlength="16"
                        autocomplete="off"
                        v-model="vrn"
                        v-on:keypress="isAllowedCharacter($event)"
                        placeholder="Reg Here"
                        required
                    />
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="10" offset="1">
                    <ion-button
                        :disabled="!isEnabled"
                        id="submitButton"
                        class="submit-button"
                        @click.prevent="saveRecords()"
                        size="large"
                        fill="solid"
                        expand="full"
                        shape="round"
                        color="light"
                    >
                        <ion-icon :icon="logIn" />
                        &nbsp;
                        <p>Submit</p>
                    </ion-button>
                    <div id="spinnerIcon" v-show="!isEnabled">
                        <h3>Adding to the whitelist</h3>
                        <ion-spinner></ion-spinner>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row v-if="this.settings.length !== 0 && this.settings.welcomeMessage !== ''">
                <ion-col size="6" offset="3">
                    <ion-text color="secondary">
                        <h3 class="messageBackground">{{ this.settings.welcomeMessage }}</h3>
                    </ion-text>
                </ion-col>
            </ion-row>
            <ion-row v-show="this.settings.customerImage !== ''">
                <ion-col size="10" offset="1">
                    <img :src="this.settings.customerImage" alt="" />
                </ion-col>
            </ion-row>
        </div>
    </ion-page>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { logIn } from 'ionicons/icons';
import { IonPage, IonRow, IonCol, IonButton, IonSpinner, IonIcon, IonItem, IonText, IonSegment, IonSegmentButton } from '@ionic/vue'
import { api } from "@/main";
import moment from 'moment';
import Swal from 'sweetalert2';

export default {
    name: 'Home',
    components: { IonPage, IonRow, IonCol, IonButton, IonSpinner, IonIcon, IonItem, IonText, IonSegment, IonSegmentButton },
    setup() {
        return {
            logIn
        }
    },
    ionViewWillEnter() {
        this.updateSettings();
    },
    mounted() {
        this.updateSettings();
    },
    data() {
        return {
            vrn: '',
            showTariff: true,
            isEnabled: true,
            lengthOfStay: 1,
            zone: null,
            errors: '',
        }
    },
    methods: {
        saveRecords() {
            // Reset Errors
            this.errors = '';

            if (this.vrn === '' || this.vrn === undefined) {
                this.errors = "You need to enter the registration number";
            } else {
                this.isEnabled = false;

                let Now = moment();
                let MomentStartTime = moment();

                let FormattedStartTime = MomentStartTime.format('HH:mm:ss');

                if (this.settings.systemType === 'Days') {
                    FormattedStartTime = '00:00:00';
                }

                if (this.zone === null) {
                    if (document.getElementById('zoneSelect')) {
                        this.zone = document.getElementById('zoneSelect')[0].value;
                    } else {
                        this.zone = '';
                    }
                }

                let stayLength = (this.settings.systemType === 'Days')
                    ? this.lengthOfStay
                    : this.settings.defaultTime;

                let EndDate = (this.settings.systemType === 'Days' && this.lengthOfStay === 1)
                    ? Now.add(0, this.settings.systemType.toLowerCase())
                    : Now.add(stayLength, this.settings.systemType.toLowerCase());

                let FormattedStartDate = MomentStartTime.format('YYYY-MM-DD');
                let FormattedEndDate = '';

                if (this.settings.systemType === 'Days') {
                    FormattedEndDate = EndDate.format('YYYY-MM-DD 23:59:59');
                } else {
                    FormattedEndDate = EndDate.format('YYYY-MM-DD HH:mm:ss');
                }

                let StartDateTime = FormattedStartDate + ' ' + FormattedStartTime;

                api.get('/api/check-in', {
                    headers: {
                        Authorization: 'Bearer: ' + this.token
                    },
                    timeout: this.settings.postDelay * 1000
                }).then((result) => {
                    if (result.status === 200) {
                        api.post('/api/whitelist', {
                                formattedStartDate: StartDateTime,
                                formattedEndDate: FormattedEndDate,
                                vrn: this.vrn.toLowerCase(),
                                zone: this.zone
                            },
                            {
                                headers: {
                                    Authorization: 'Bearer: ' + this.token
                                },
                                timeout: this.settings.postDelay * 1000
                            }
                        ).then((response) => {
                            if (response.status === 200) {
                                if (response.data.error === false) {

                                    Swal.fire(
                                        'Vehicle Added',
                                        (this.settings.systemType === 'Days' && this.lengthOfStay === 1)
                                            ? 'Your parking for ' + this.vrn.toUpperCase() + ' been recorded for the day'
                                            : 'Your parking for ' + this.vrn.toUpperCase() + ' been recorded<br/>This Expires at ' + EndDate.format('DD/MM/YYYY HH:mm:ss'),
                                        'success'
                                    );

                                    this.isEnabled = true;
                                    this.vrn = '';

                                    setTimeout(() => {
                                        Swal.close();
                                    }, 5000);
                                } else {

                                    Swal.fire(
                                        'Failed to Add!',
                                        'Error Code: ' + response.data.status_code,
                                        'error'
                                    )

                                    this.isEnabled = true;

                                }
                            } else {
                                this.errors = 'Response Error. ' + response.statusText;
                                this.isEnabled = true;
                            }

                        }).catch((error) => {
                            if (error.length === 0) {
                                this.saveRecords();
                            }
                            else if (error.response.status === 422) {
                                console.log("You're a " + this.vrn + " and so is your mum. 🖕");
                                let errorKey = Object.keys(error.response.data.errors)[0];

                                Swal.fire(
                                    'Failed to Add!',
                                    'We could not add you to the whitelist, ' + error.response.data.errors[errorKey][0],
                                    'error'
                                );

                                this.isEnabled = true;
                                this.vrn = '';
                            } else if (error.response.status === 401) {
                                this.isEnabled = true;
                                this.vrn = '';

                                this.notInUse(error.response.data);
                            } else if (error.response.status === 500) {

                                Swal.fire(
                                    'Failed to Add!',
                                    'We could not add ' + this.vrn.toUpperCase() + ', please check for incorrect characters and try again',
                                    'error'
                                );

                                this.isEnabled = true;

                            } else {
                                this.errors = 'Request timed out after ' + this.settings.postDelay + ' seconds.';
                                setTimeout(() => {
                                    this.isEnabled = true;
                                    this.vrn = '';
                                    this.errors = '';
                                }, 5000);
                            }
                        });
                    }
                }).catch(() => {
                    this.errors = 'Server did not respond in a timely fashion';
                    this.isEnabled = true;
                });
            }
        },
        updateSettings() {
            if (
                this.token !== ''
                && this.settings.length === 0
            ) {
                api.get('/api/settings', {
                    headers: {
                        Authorization: 'Bearer: ' + this.token
                    }
                }).then((response) => {
                    response.data.forEach((value) => {
                        this[value.setting_name] = (value.setting_name === 'zoneName' || value.setting_name === 'zoneCode')
                            ? JSON.parse(value.setting_value)
                            : value.setting_value;
                    })
                }).finally(() => {
                    this.setSettings(this.settings).then(() => {
                        this.$router.go();
                    });
                });
            }
        },
        setZone(value) {
            this.zone = value;
        },
        ...mapActions('users', {
            setSettings: 'setSettings'
        }),
        isAllowedCharacter(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/[a-zA-Z0-9\s]+/.test(char)) {
                return true;
            }

            e.preventDefault();
        },
        notInUse(message) {
            Swal.fire(
                'Not in Use',
                message,
                'error'
            ).then(() => {
                this.$router.push('/tabs/reauthenticate');
            });
        }
    },
    computed: {
        ...mapGetters('users', {
            loggedIn: 'loggedIn',
            settings: 'settings',
            token: 'token',
        }),
        dayNightsHours() {
            if (this.settings.daysNights === '') {
                return 'days/nights';
            } else if (this.settings.systemType === 'Hours') {
                return 'hours';
            } else {
                return this.settings.daysNights.toLowerCase();
            }
        }
    }
}
</script>

<style scoped>
    #container {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    #container strong {
        font-size: 20px;
        line-height: 26px;
    }

    #container p {
        font-size: 16px;
        line-height: 22px;
        color: #8c8c8c;
        margin: 0;
    }

    #container a {
        text-decoration: none;
    }

    ion-content {
        margin-top:20px;
        width: 98%;
    }

    ion-row input {
        width: 100%;
        padding:20px;
        border:2px solid #666666;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        font-size:60px;
        background-color:yellow;
        text-align: center;
        margin-top: 0;
    }

    select {
        width: 100%;
        padding: 10px;
        border: 2px solid #666666;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        font-size: 25px;
        background-color: yellow;
        margin-top: 5px;
    }

    ion-row .reg2 {
        text-transform: uppercase;
    }

    ion-icon {
        color: #454545
    }

    input[placeholder], [placeholder], *[placeholder] {
        color: #000000 !important;
    }

    .submit-button p {
        font-size:35px;
    }

    img { margin:0 auto; }

    ion-spinner {
        font-size: 80px;
    }

    ion-item#sliderOverflow {
        overflow: visible;
    }

    ion-segment {
        width: 100% !important;
    }

    .messageBackground {
        background: white;
        border-radius: 5em;
        box-shadow: 6px 6px 12px 0 lightgrey;
        padding: 0.5em;
    }
</style>